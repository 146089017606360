<template>
    <div>
        <div class="level  ">
            
            <div class=" level-item  level-left ">
                <button class="button" :class="{'is-success': all_applicants == 1}" @click="updateFilters('all_applicants')">All Applications</button>
                <button class="button ml-2" :class="{'is-success': unsubmitted == 1}" @click="updateFilters('unsubmitted')">Unsubmitted Applications</button>
                <button class="button ml-2" :class="{'is-success': new_applicants == 1}" @click="updateFilters('new_applicants')">New Applicants</button>
                <button class="button ml-2" :class="{'is-success': waiting_member == 1}" @click="updateFilters('waiting_member')">Waiting on Member</button>
                <button class="button ml-2" :class="{'is-success': waiting_balmoral == 1}" @click="updateFilters('waiting_balmoral')">Needs Action From Balmoral</button>

                
            </div>

            <div class="level-item ml-2">
                <input class="input" type="text" v-model="search" placeholder="Search ..." :disabled="loading_summary==true" required v-on:keyup.enter="searchApplications()" >
            </div>

           
            <div class="level-right level-item">
                <router-link to="/applications/general-report"><button class="is-link button">General Report</button></router-link>
            
            </div>
        </div>
        
        <div class="notification is-warning" v-if="loading_summary == true">
            Loading applications ...
        </div>
        
        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading_summary == false">
            <thead>
                <tr>
                    <th>Application Date</th>
                    <th>Name</th>
                    <th>Postcode</th>
                    <th>Grade</th>
                    <th>Personal</th>
                    <th>Health</th>
                    <th>Skills</th>
                    <th>Nurse Skills</th>
                    <th>Paramedic Skills</th>
                    <th>Union</th>
                    <th>Employment</th>
                    <th>Refs</th>
                    <th>Training</th>
                    <th>NISCC</th>
                    <th>NMC</th>
                    <th >HCPC</th>
                    <th>Interview</th>
                    <th>Last Applicant Action</th>
                    <th>Last Admin Action</th>
                    <th >&nbsp;</th>
                    <th >&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_applications" :key="row.member_application_id" :class="{'has-background-primary' : row.last_applicant_action > row.last_admin_action && waiting_balmoral == 0, 'has-text-white' : row.last_applicant_action > row.last_admin_action && waiting_balmoral == 0} "> 
                    <td>{{ row.date }}</td>
                    <td><router-link :to="'/applications/members/' + row.member_id" target="_blank">{{ row.forename }} {{ row.surname }}</router-link></td>
                    <td>{{ row.postcode }}</td>
                     <td >
                        <div class="tags"><span v-for="grade in row.grades" :key="grade.name" class="tag">{{ grade.grade_code}}</span></div>
                    </td>
                    <!-- Personal Details -->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(1, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(1, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(1, row.stages)">Passed</span>
                    </td>
                    <!-- Health-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(2, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(2, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(2, row.stages)">Passed</span>
                    </td>
                    <!-- Skills-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(3, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(3, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(3, row.stages)">Passed</span>
                    </td>
                    <!-- Nurse Skills-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(14, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(14, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(14, row.stages)">Passed</span>
                    </td>
                    <!-- Paramedic Skills-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(15, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(15, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(15, row.stages)">Passed</span>
                    </td>
                    <!-- Union -->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(17, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(17, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(17, row.stages)">Passed</span>
                    </td>
                    <!-- Employment-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(16, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(16, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(16, row.stages)">Passed</span>
                    </td>
                     <!-- References-->
                     <td>
                        <span class="tag is-light" v-if="isStageActive(7, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(7, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(7, row.stages)">Passed</span>
                    </td>
                    <!-- Training-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(6, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(6, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(6, row.stages)">Passed</span>
                    </td>
                    <!-- NISCC-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(10, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(10, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(10, row.stages)">Passed</span>
                    </td>
                    <!-- NMC-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(11, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(11, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(11, row.stages)">Passed</span>
                    </td>
                    <!-- HCPC-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(12, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(12, row.stages)">Done</span>
                        <span class="tag is-success" v-if="isStageApproved(12, row.stages)">Passed</span>
                    </td>
                     <!-- Interview-->
                     <td>
                        <span class="tag is-light" v-if="isStageActive(5, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(5, row.stages)">Arranged</span>
                        <span class="tag is-success" v-if="isStageApproved(5, row.stages)">Passed</span>
                    </td>
                    <td>{{ row.last_applicant_action }}</td>
                    <td>{{ row.last_admin_action }}</td>
                    <td>
                        <span v-if="row.cleared==0">{{ row.status_name }}</span>
                        <span v-if="row.cleared==1" class="tag is-danger">CLEARED</span>
                    </td>
                    <td style="display:none"><router-link :to="'/applications/' + row.member_application_id"><span class="tag is-small is-success">View</span></router-link></td>
                    <td><router-link :to="'/applications/members/' + row.member_id" target="_blank"><span class="tag is-small is-info">View</span></router-link></td>
                </tr>
            </tbody>

        </table>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import ApplicationsList from '@/components/Applications/ApplicationsList.vue';

export default {
    
      name: 'ApplicationsDashboard',
        mixins: [MemberMixin],
        components: {
            //ApplicationsList
        },
        data: function () {
            return {
                   search: '',
                   applications: [],
                   loading:true,
                   loading_summary: true,
                   loading_search: false,
                   search_applications: [],
                   search_performed: false,
                   all_applicants: 1,
                   unsubmitted: 0,
                   new_applicants: 0,
                   waiting_member: 0,
                   waiting_balmoral: 0,
                   
            }
        },
        methods: {
            updateFilters(filter_name)
            {
                if(filter_name == "all_applicants")
                {
                    this.all_applicants = 1
                    this.unsubmitted = 0;
                    this.new_applicants = 0;
                    this.waiting_member = 0;
                    this.waiting_balmoral = 0;
                }
                else if(filter_name == "unsubmitted")
                {
                    this.all_applicants = 0;
                    this.unsubmitted = 1;
                    this.new_applicants = 0;
                    this.waiting_member = 0;
                    this.waiting_balmoral = 0;
                }
                else if(filter_name == "new_applicants")
                {
                    this.all_applicants = 0;
                    this.unsubmitted = 0;
                    this.new_applicants = 1;
                    this.waiting_member = 0;
                    this.waiting_balmoral = 0;
                }
                else if(filter_name == "waiting_member")
                {
                    this.all_applicants = 0;
                    this.unsubmitted = 0;
                    this.new_applicants = 0;
                    this.waiting_member = 1;
                    this.waiting_balmoral = 0;
                }
                else if(filter_name == "waiting_balmoral")
                {
                    this.all_applicants = 0;
                    this.unsubmitted = 0;
                    this.new_applicants = 0;
                    this.waiting_member = 0;
                    this.waiting_balmoral = 1;
                }
               
                this.getApplications();
            },  
            searchApplications() {
                 this.all_applicants = 1
                    this.unsubmitted = 0;
                    this.new_applicants = 0;
                    this.waiting_member = 0;
                    this.waiting_balmoral = 0;

                    this.getApplications();
                
            },
            isStageActive(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.active === 1 && stage.started === 0 && stage.approved === 0) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            isStageCompleted(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.started === 1 && stage.approved === 0) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            isStageApproved(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.approved === 1) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            async getApplications() {
                this.applications = [];

                this.loading_summary=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications?search=" + this.search + "&unsubmitted=" + this.unsubmitted + "&new_applicants=" + this.new_applicants + "&waiting_member=" + this.waiting_member + "&waiting_balmoral=" + this.waiting_balmoral)
                .then(
                    (applications => {
                        this.$set(this, "applications", applications);
                        this.loading_summary=false
                    }).bind(this)
                );
            },
            
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplications();
            });
        
        },
        computed : {
            /*open_applications: function () {
                let open_applications =  this.applications.filter(function(u) {
                    if(  u.status == 0) 
                    {
                        return 1
                    }
                })
                return open_applications
            },
            new_applications: function () {
                let new_applications =  this.applications.filter(function(u) {
                    if(  u.status == 1) 
                    {
                        return 1
                    }
                })
                return new_applications
            },
            in_progress_applications: function () {
                let in_progress_applications =  this.applications.filter(function(u) {
                    if(  u.status > 1) 
                    {
                    return 1
                    }
                })
                return in_progress_applications
            },
            pending_applications: function () {
                let pending_applications =  this.applications.filter(function(u) {
                    if(  u.status == 3) 
                    {
                    return 1
                    }
                })
                return pending_applications
            }, */
            filtered_applications: function () {

                let filtered_applications = this.applications;

                
                return filtered_applications
            }
        },
        watch: {
            
            ready_for_interview() {
                this.getApplications();
                
            }
        },
    }
</script>